const dateFormats = [
  { id: 1, style: "DD/MM/YYYY", format: "dd/LL/yyyy", example: "15/08/2021" },
  {
    id: 2,
    style: "DD/MMM/YYYY",
    format: "dd/LLL/yyyy",
    example: "15/Aug/2021",
  },
  { id: 3, style: "MM/DD/YYYY", format: "LL/dd/yyyy", example: "08/15/2021" },
  {
    id: 4,
    style: "MMM/DD/YYYY",
    format: "LLL/dd/yyyy",
    example: "Aug/15/2021",
  },
  { id: 5, style: "YYYY/MM/DD", format: "yyyy/LL/dd", example: "2021/08/02" },
  {
    id: 6,
    style: "YYYY/MMM/DD",
    format: "yyyy/LLL/dd",
    example: "2021/Aug/02",
  },
];

const timeFormats = [
  { id: 1, style: "24 Hour", format: "HH:mm", example: "21:15" },
  { id: 2, style: "12 Hour", format: "hh:mm a", example: "09:15 PM" },
];

const timeZones = [
  { id: 1, name: "America/Adak", offset: "-1000" },
  { id: 2, name: "America/Anchorage", offset: "-0900" },
  { id: 3, name: "America/Anguilla", offset: "-0400" },
  { id: 4, name: "America/Antigua", offset: "-0400" },
  { id: 5, name: "America/Araguaina", offset: "-0300" },
  { id: 6, name: "America/Argentina/Buenos_Aires", offset: "-0300" },
  { id: 7, name: "America/Argentina/Catamarca", offset: "-0300" },
  { id: 8, name: "America/Argentina/ComodRivadavia", offset: "-0300" },
  { id: 9, name: "America/Argentina/Cordoba", offset: "-0300" },
  { id: 10, name: "America/Argentina/Jujuy", offset: "-0300" },
  { id: 11, name: "America/Argentina/La_Rioja", offset: "-0300" },
  { id: 12, name: "America/Argentina/Mendoza", offset: "-0300" },
  { id: 13, name: "America/Argentina/Rio_Gallegos", offset: "-0300" },
  { id: 14, name: "America/Argentina/Salta", offset: "-0300" },
  { id: 15, name: "America/Argentina/San_Juan", offset: "-0300" },
  { id: 16, name: "America/Argentina/San_Luis", offset: "-0300" },
  { id: 17, name: "America/Argentina/Tucuman", offset: "-0300" },
  { id: 18, name: "America/Argentina/Ushuaia", offset: "-0300" },
  { id: 19, name: "America/Aruba", offset: "-0400" },
  { id: 20, name: "America/Asuncion", offset: "-0300" },
  { id: 21, name: "America/Atikokan", offset: "-0500" },
  { id: 22, name: "America/Atka", offset: "-1000" },
  { id: 23, name: "America/Bahia", offset: "-0300" },
  { id: 24, name: "America/Bahia_Banderas", offset: "-0600" },
  { id: 25, name: "America/Barbados", offset: "-0400" },
  { id: 26, name: "America/Belem", offset: "-0300" },
  { id: 27, name: "America/Belize", offset: "-0600" },
  { id: 28, name: "America/Blanc-Sablon", offset: "-0400" },
  { id: 29, name: "America/Boa_Vista", offset: "-0400" },
  { id: 30, name: "America/Bogota", offset: "-0500" },
  { id: 31, name: "America/Boise", offset: "-0700" },
  { id: 32, name: "America/Buenos_Aires", offset: "-0300" },
  { id: 33, name: "America/Cambridge_Bay", offset: "-0700" },
  { id: 34, name: "America/Campo_Grande", offset: "-0400" },
  { id: 35, name: "America/Cancun", offset: "-0500" },
  { id: 36, name: "America/Caracas", offset: "-0400" },
  { id: 37, name: "America/Catamarca", offset: "-0300" },
  { id: 38, name: "America/Cayenne", offset: "-0300" },
  { id: 39, name: "America/Cayman", offset: "-0500" },
  { id: 40, name: "America/Chicago", offset: "-0600" },
  { id: 41, name: "America/Chihuahua", offset: "-0700" },
  { id: 42, name: "America/Coral_Harbour", offset: "-0500" },
  { id: 43, name: "America/Cordoba", offset: "-0300" },
  { id: 44, name: "America/Costa_Rica", offset: "-0600" },
  { id: 45, name: "America/Creston", offset: "-0700" },
  { id: 46, name: "America/Cuiaba", offset: "-0400" },
  { id: 47, name: "America/Curacao", offset: "-0400" },
  { id: 48, name: "America/Danmarkshavn", offset: "+0000" },
  { id: 49, name: "America/Dawson", offset: "-0700" },
  { id: 50, name: "America/Dawson_Creek", offset: "-0700" },
  { id: 51, name: "America/Denver", offset: "-0700" },
  { id: 52, name: "America/Detroit", offset: "-0500" },
  { id: 53, name: "America/Dominica", offset: "-0400" },
  { id: 54, name: "America/Edmonton", offset: "-0700" },
  { id: 55, name: "America/Eirunepe", offset: "-0500" },
  { id: 56, name: "America/El_Salvador", offset: "-0600" },
  { id: 57, name: "America/Ensenada", offset: "-0800" },
  { id: 58, name: "America/Fort_Nelson", offset: "-0700" },
  { id: 59, name: "America/Fort_Wayne", offset: "-0500" },
  { id: 60, name: "America/Fortaleza", offset: "-0300" },
  { id: 61, name: "America/Glace_Bay", offset: "-0400" },
  { id: 62, name: "America/Godthab", offset: "-0300" },
  { id: 63, name: "America/Goose_Bay", offset: "-0400" },
  { id: 64, name: "America/Grand_Turk", offset: "-0500" },
  { id: 65, name: "America/Grenada", offset: "-0400" },
  { id: 66, name: "America/Guadeloupe", offset: "-0400" },
  { id: 67, name: "America/Guatemala", offset: "-0600" },
  { id: 68, name: "America/Guayaquil", offset: "-0500" },
  { id: 69, name: "America/Guyana", offset: "-0400" },
  { id: 70, name: "America/Halifax", offset: "-0400" },
  { id: 71, name: "America/Havana", offset: "-0500" },
  { id: 72, name: "America/Hermosillo", offset: "-0700" },
  { id: 73, name: "America/Indiana/Indianapolis", offset: "-0500" },
  { id: 74, name: "America/Indiana/Knox", offset: "-0600" },
  { id: 75, name: "America/Indiana/Marengo", offset: "-0500" },
  { id: 76, name: "America/Indiana/Petersburg", offset: "-0500" },
  { id: 77, name: "America/Indiana/Tell_City", offset: "-0600" },
  { id: 78, name: "America/Indiana/Vevay", offset: "-0500" },
  { id: 79, name: "America/Indiana/Vincennes", offset: "-0500" },
  { id: 80, name: "America/Indiana/Winamac", offset: "-0500" },
  { id: 81, name: "America/Indianapolis", offset: "-0500" },
  { id: 82, name: "America/Inuvik", offset: "-0700" },
  { id: 83, name: "America/Iqaluit", offset: "-0500" },
  { id: 84, name: "America/Jamaica", offset: "-0500" },
  { id: 85, name: "America/Jujuy", offset: "-0300" },
  { id: 86, name: "America/Juneau", offset: "-0900" },
  { id: 87, name: "America/Kentucky/Louisville", offset: "-0500" },
  { id: 88, name: "America/Kentucky/Monticello", offset: "-0500" },
  { id: 89, name: "America/Knox_IN", offset: "-0600" },
  { id: 90, name: "America/Kralendijk", offset: "-0400" },
  { id: 91, name: "America/La_Paz", offset: "-0400" },
  { id: 92, name: "America/Lima", offset: "-0500" },
  { id: 93, name: "America/Los_Angeles", offset: "-0800" },
  { id: 94, name: "America/Louisville", offset: "-0500" },
  { id: 95, name: "America/Lower_Princes", offset: "-0400" },
  { id: 96, name: "America/Maceio", offset: "-0300" },
  { id: 97, name: "America/Managua", offset: "-0600" },
  { id: 98, name: "America/Manaus", offset: "-0400" },
  { id: 99, name: "America/Marigot", offset: "-0400" },
  { id: 100, name: "America/Martinique", offset: "-0400" },
  { id: 101, name: "America/Matamoros", offset: "-0600" },
  { id: 102, name: "America/Mazatlan", offset: "-0700" },
  { id: 103, name: "America/Mendoza", offset: "-0300" },
  { id: 104, name: "America/Menominee", offset: "-0600" },
  { id: 105, name: "America/Merida", offset: "-0600" },
  { id: 106, name: "America/Metlakatla", offset: "-0900" },
  { id: 107, name: "America/Mexico_City", offset: "-0600" },
  { id: 108, name: "America/Miquelon", offset: "-0300" },
  { id: 109, name: "America/Moncton", offset: "-0400" },
  { id: 110, name: "America/Monterrey", offset: "-0600" },
  { id: 111, name: "America/Montevideo", offset: "-0300" },
  { id: 112, name: "America/Montreal", offset: "-0500" },
  { id: 113, name: "America/Montserrat", offset: "-0400" },
  { id: 114, name: "America/Nassau", offset: "-0500" },
  { id: 115, name: "America/New_York", offset: "-0500" },
  { id: 116, name: "America/Nipigon", offset: "-0500" },
  { id: 117, name: "America/Nome", offset: "-0900" },
  { id: 118, name: "America/Noronha", offset: "-0200" },
  { id: 119, name: "America/North_Dakota/Beulah", offset: "-0600" },
  { id: 120, name: "America/North_Dakota/Center", offset: "-0600" },
  { id: 121, name: "America/North_Dakota/New_Salem", offset: "-0600" },
  { id: 122, name: "America/Nuuk", offset: "-0300" },
  { id: 123, name: "America/Ojinaga", offset: "-0700" },
  { id: 124, name: "America/Panama", offset: "-0500" },
  { id: 125, name: "America/Pangnirtung", offset: "-0500" },
  { id: 126, name: "America/Paramaribo", offset: "-0300" },
  { id: 127, name: "America/Phoenix", offset: "-0700" },
  { id: 128, name: "America/Port-au-Prince", offset: "-0500" },
  { id: 129, name: "America/Port_of_Spain", offset: "-0400" },
  { id: 130, name: "America/Porto_Acre", offset: "-0500" },
  { id: 131, name: "America/Porto_Velho", offset: "-0400" },
  { id: 132, name: "America/Puerto_Rico", offset: "-0400" },
  { id: 133, name: "America/Punta_Arenas", offset: "-0300" },
  { id: 134, name: "America/Rainy_River", offset: "-0600" },
  { id: 135, name: "America/Rankin_Inlet", offset: "-0600" },
  { id: 136, name: "America/Recife", offset: "-0300" },
  { id: 137, name: "America/Regina", offset: "-0600" },
  { id: 138, name: "America/Resolute", offset: "-0600" },
  { id: 139, name: "America/Rio_Branco", offset: "-0500" },
  { id: 140, name: "America/Rosario", offset: "-0300" },
  { id: 141, name: "America/Santa_Isabel", offset: "-0800" },
  { id: 142, name: "America/Santarem", offset: "-0300" },
  { id: 143, name: "America/Santiago", offset: "-0300" },
  { id: 144, name: "America/Santo_Domingo", offset: "-0400" },
  { id: 145, name: "America/Sao_Paulo", offset: "-0300" },
  { id: 146, name: "America/Scoresbysund", offset: "-0100" },
  { id: 147, name: "America/Shiprock", offset: "-0700" },
  { id: 148, name: "America/Sitka", offset: "-0900" },
  { id: 149, name: "America/St_Barthelemy", offset: "-0400" },
  { id: 150, name: "America/St_Johns", offset: "-0330" },
  { id: 151, name: "America/St_Kitts", offset: "-0400" },
  { id: 152, name: "America/St_Lucia", offset: "-0400" },
  { id: 153, name: "America/St_Thomas", offset: "-0400" },
  { id: 154, name: "America/St_Vincent", offset: "-0400" },
  { id: 155, name: "America/Swift_Current", offset: "-0600" },
  { id: 156, name: "America/Tegucigalpa", offset: "-0600" },
  { id: 157, name: "America/Thule", offset: "-0400" },
  { id: 158, name: "America/Thunder_Bay", offset: "-0500" },
  { id: 159, name: "America/Tijuana", offset: "-0800" },
  { id: 160, name: "America/Toronto", offset: "-0500" },
  { id: 161, name: "America/Tortola", offset: "-0400" },
  { id: 162, name: "America/Vancouver", offset: "-0800" },
  { id: 163, name: "America/Virgin", offset: "-0400" },
  { id: 164, name: "America/Whitehorse", offset: "-0700" },
  { id: 165, name: "America/Winnipeg", offset: "-0600" },
  { id: 166, name: "America/Yakutat", offset: "-0900" },
  { id: 167, name: "America/Yellowknife", offset: "-0700" },
  { id: 168, name: "Asia/Aden", offset: "+0300" },
  { id: 169, name: "Asia/Almaty", offset: "+0600" },
  { id: 170, name: "Asia/Amman", offset: "+0200" },
  { id: 171, name: "Asia/Anadyr", offset: "+1200" },
  { id: 172, name: "Asia/Aqtau", offset: "+0500" },
  { id: 173, name: "Asia/Aqtobe", offset: "+0500" },
  { id: 174, name: "Asia/Ashgabat", offset: "+0500" },
  { id: 175, name: "Asia/Ashkhabad", offset: "+0500" },
  { id: 176, name: "Asia/Atyrau", offset: "+0500" },
  { id: 177, name: "Asia/Baghdad", offset: "+0300" },
  { id: 178, name: "Asia/Bahrain", offset: "+0300" },
  { id: 179, name: "Asia/Baku", offset: "+0400" },
  { id: 180, name: "Asia/Bangkok", offset: "+0700" },
  { id: 181, name: "Asia/Barnaul", offset: "+0700" },
  { id: 182, name: "Asia/Beirut", offset: "+0200" },
  { id: 183, name: "Asia/Bishkek", offset: "+0600" },
  { id: 184, name: "Asia/Brunei", offset: "+0800" },
  { id: 185, name: "Asia/Calcutta", offset: "+0530" },
  { id: 186, name: "Asia/Chita", offset: "+0900" },
  { id: 187, name: "Asia/Choibalsan", offset: "+0800" },
  { id: 188, name: "Asia/Chongqing", offset: "+0800" },
  { id: 189, name: "Asia/Chungking", offset: "+0800" },
  { id: 190, name: "Asia/Colombo", offset: "+0530" },
  { id: 191, name: "Asia/Dacca", offset: "+0600" },
  { id: 192, name: "Asia/Damascus", offset: "+0200" },
  { id: 193, name: "Asia/Dhaka", offset: "+0600" },
  { id: 194, name: "Asia/Dili", offset: "+0900" },
  { id: 195, name: "Asia/Dubai", offset: "+0400" },
  { id: 196, name: "Asia/Dushanbe", offset: "+0500" },
  { id: 197, name: "Asia/Famagusta", offset: "+0200" },
  { id: 198, name: "Asia/Gaza", offset: "+0200" },
  { id: 199, name: "Asia/Harbin", offset: "+0800" },
  { id: 200, name: "Asia/Hebron", offset: "+0200" },
  { id: 201, name: "Asia/Ho_Chi_Minh", offset: "+0700" },
  { id: 202, name: "Asia/Hong_Kong", offset: "+0800" },
  { id: 203, name: "Asia/Hovd", offset: "+0700" },
  { id: 204, name: "Asia/Irkutsk", offset: "+0800" },
  { id: 205, name: "Asia/Istanbul", offset: "+0300" },
  { id: 206, name: "Asia/Jakarta", offset: "+0700" },
  { id: 207, name: "Asia/Jayapura", offset: "+0900" },
  { id: 208, name: "Asia/Jerusalem", offset: "+0200" },
  { id: 209, name: "Asia/Kabul", offset: "+0430" },
  { id: 210, name: "Asia/Kamchatka", offset: "+1200" },
  { id: 211, name: "Asia/Karachi", offset: "+0500" },
  { id: 212, name: "Asia/Kashgar", offset: "+0600" },
  { id: 213, name: "Asia/Kathmandu", offset: "+0545" },
  { id: 214, name: "Asia/Katmandu", offset: "+0545" },
  { id: 215, name: "Asia/Khandyga", offset: "+0900" },
  { id: 216, name: "Asia/Kolkata", offset: "+0530" },
  { id: 217, name: "Asia/Krasnoyarsk", offset: "+0700" },
  { id: 218, name: "Asia/Kuala_Lumpur", offset: "+0800" },
  { id: 219, name: "Asia/Kuching", offset: "+0800" },
  { id: 220, name: "Asia/Kuwait", offset: "+0300" },
  { id: 221, name: "Asia/Macao", offset: "+0800" },
  { id: 222, name: "Asia/Macau", offset: "+0800" },
  { id: 223, name: "Asia/Magadan", offset: "+1100" },
  { id: 224, name: "Asia/Makassar", offset: "+0800" },
  { id: 225, name: "Asia/Manila", offset: "+0800" },
  { id: 226, name: "Asia/Muscat", offset: "+0400" },
  { id: 227, name: "Asia/Nicosia", offset: "+0200" },
  { id: 228, name: "Asia/Novokuznetsk", offset: "+0700" },
  { id: 229, name: "Asia/Novosibirsk", offset: "+0700" },
  { id: 230, name: "Asia/Omsk", offset: "+0600" },
  { id: 231, name: "Asia/Oral", offset: "+0500" },
  { id: 232, name: "Asia/Phnom_Penh", offset: "+0700" },
  { id: 233, name: "Asia/Pontianak", offset: "+0700" },
  { id: 234, name: "Asia/Pyongyang", offset: "+0900" },
  { id: 235, name: "Asia/Qatar", offset: "+0300" },
  { id: 236, name: "Asia/Qostanay", offset: "+0600" },
  { id: 237, name: "Asia/Qyzylorda", offset: "+0500" },
  { id: 238, name: "Asia/Rangoon", offset: "+0630" },
  { id: 239, name: "Asia/Riyadh", offset: "+0300" },
  { id: 240, name: "Asia/Saigon", offset: "+0700" },
  { id: 241, name: "Asia/Sakhalin", offset: "+1100" },
  { id: 242, name: "Asia/Samarkand", offset: "+0500" },
  { id: 243, name: "Asia/Seoul", offset: "+0900" },
  { id: 244, name: "Asia/Shanghai", offset: "+0800" },
  { id: 245, name: "Asia/Singapore", offset: "+0800" },
  { id: 246, name: "Asia/Srednekolymsk", offset: "+1100" },
  { id: 247, name: "Asia/Taipei", offset: "+0800" },
  { id: 248, name: "Asia/Tashkent", offset: "+0500" },
  { id: 249, name: "Asia/Tbilisi", offset: "+0400" },
  { id: 250, name: "Asia/Tehran", offset: "+0330" },
  { id: 251, name: "Asia/Tel_Aviv", offset: "+0200" },
  { id: 252, name: "Asia/Thimbu", offset: "+0600" },
  { id: 253, name: "Asia/Thimphu", offset: "+0600" },
  { id: 254, name: "Asia/Tokyo", offset: "+0900" },
  { id: 255, name: "Asia/Tomsk", offset: "+0700" },
  { id: 256, name: "Asia/Ujung_Pandang", offset: "+0800" },
  { id: 257, name: "Asia/Ulaanbaatar", offset: "+0800" },
  { id: 258, name: "Asia/Ulan_Bator", offset: "+0800" },
  { id: 259, name: "Asia/Urumqi", offset: "+0600" },
  { id: 260, name: "Asia/Ust-Nera", offset: "+1000" },
  { id: 261, name: "Asia/Vientiane", offset: "+0700" },
  { id: 262, name: "Asia/Vladivostok", offset: "+1000" },
  { id: 263, name: "Asia/Yakutsk", offset: "+0900" },
  { id: 264, name: "Asia/Yangon", offset: "+0630" },
  { id: 265, name: "Asia/Yekaterinburg", offset: "+0500" },
  { id: 266, name: "Asia/Yerevan", offset: "+0400" },
  { id: 267, name: "Canada/Atlantic", offset: "-0400" },
  { id: 268, name: "Canada/Central", offset: "-0600" },
  { id: 269, name: "Canada/Eastern", offset: "-0500" },
  { id: 270, name: "Canada/Mountain", offset: "-0700" },
  { id: 271, name: "Canada/Newfoundland", offset: "-0330" },
  { id: 272, name: "Canada/Pacific", offset: "-0800" },
  { id: 273, name: "Canada/Saskatchewan", offset: "-0600" },
  { id: 274, name: "Canada/Yukon", offset: "-0700" },
  { id: 275, name: "Europe/Amsterdam", offset: "+0100" },
  { id: 276, name: "Europe/Andorra", offset: "+0100" },
  { id: 277, name: "Europe/Astrakhan", offset: "+0400" },
  { id: 278, name: "Europe/Athens", offset: "+0200" },
  { id: 279, name: "Europe/Belfast", offset: "+0000" },
  { id: 280, name: "Europe/Belgrade", offset: "+0100" },
  { id: 281, name: "Europe/Berlin", offset: "+0100" },
  { id: 282, name: "Europe/Bratislava", offset: "+0100" },
  { id: 283, name: "Europe/Brussels", offset: "+0100" },
  { id: 284, name: "Europe/Bucharest", offset: "+0200" },
  { id: 285, name: "Europe/Budapest", offset: "+0100" },
  { id: 286, name: "Europe/Busingen", offset: "+0100" },
  { id: 287, name: "Europe/Chisinau", offset: "+0200" },
  { id: 288, name: "Europe/Copenhagen", offset: "+0100" },
  { id: 289, name: "Europe/Dublin", offset: "+0000" },
  { id: 290, name: "Europe/Gibraltar", offset: "+0100" },
  { id: 291, name: "Europe/Guernsey", offset: "+0000" },
  { id: 292, name: "Europe/Helsinki", offset: "+0200" },
  { id: 293, name: "Europe/Isle_of_Man", offset: "+0000" },
  { id: 294, name: "Europe/Istanbul", offset: "+0300" },
  { id: 295, name: "Europe/Jersey", offset: "+0000" },
  { id: 296, name: "Europe/Kaliningrad", offset: "+0200" },
  { id: 297, name: "Europe/Kiev", offset: "+0200" },
  { id: 298, name: "Europe/Kirov", offset: "+0300" },
  { id: 299, name: "Europe/Lisbon", offset: "+0000" },
  { id: 300, name: "Europe/Ljubljana", offset: "+0100" },
  { id: 301, name: "Europe/London", offset: "+0000" },
  { id: 302, name: "Europe/Luxembourg", offset: "+0100" },
  { id: 303, name: "Europe/Madrid", offset: "+0100" },
  { id: 304, name: "Europe/Malta", offset: "+0100" },
  { id: 305, name: "Europe/Mariehamn", offset: "+0200" },
  { id: 306, name: "Europe/Minsk", offset: "+0300" },
  { id: 307, name: "Europe/Monaco", offset: "+0100" },
  { id: 308, name: "Europe/Moscow", offset: "+0300" },
  { id: 309, name: "Europe/Nicosia", offset: "+0200" },
  { id: 310, name: "Europe/Oslo", offset: "+0100" },
  { id: 311, name: "Europe/Paris", offset: "+0100" },
  { id: 312, name: "Europe/Podgorica", offset: "+0100" },
  { id: 313, name: "Europe/Prague", offset: "+0100" },
  { id: 314, name: "Europe/Riga", offset: "+0200" },
  { id: 315, name: "Europe/Rome", offset: "+0100" },
  { id: 316, name: "Europe/Samara", offset: "+0400" },
  { id: 317, name: "Europe/San_Marino", offset: "+0100" },
  { id: 318, name: "Europe/Sarajevo", offset: "+0100" },
  { id: 319, name: "Europe/Saratov", offset: "+0400" },
  { id: 320, name: "Europe/Simferopol", offset: "+0300" },
  { id: 321, name: "Europe/Skopje", offset: "+0100" },
  { id: 322, name: "Europe/Sofia", offset: "+0200" },
  { id: 323, name: "Europe/Stockholm", offset: "+0100" },
  { id: 324, name: "Europe/Tallinn", offset: "+0200" },
  { id: 325, name: "Europe/Tirane", offset: "+0100" },
  { id: 326, name: "Europe/Tiraspol", offset: "+0200" },
  { id: 327, name: "Europe/Ulyanovsk", offset: "+0400" },
  { id: 328, name: "Europe/Uzhgorod", offset: "+0200" },
  { id: 329, name: "Europe/Vaduz", offset: "+0100" },
  { id: 330, name: "Europe/Vatican", offset: "+0100" },
  { id: 331, name: "Europe/Vienna", offset: "+0100" },
  { id: 332, name: "Europe/Vilnius", offset: "+0200" },
  { id: 333, name: "Europe/Volgograd", offset: "+0300" },
  { id: 334, name: "Europe/Warsaw", offset: "+0100" },
  { id: 335, name: "Europe/Zagreb", offset: "+0100" },
  { id: 336, name: "Europe/Zaporozhye", offset: "+0200" },
  { id: 337, name: "Europe/Zurich", offset: "+0100" },
  { id: 338, name: "US/Alaska", offset: "-0900" },
  { id: 339, name: "US/Aleutian", offset: "-1000" },
  { id: 340, name: "US/Arizona", offset: "-0700" },
  { id: 341, name: "US/Central", offset: "-0600" },
  { id: 342, name: "US/East-Indiana", offset: "-0500" },
  { id: 343, name: "US/Eastern", offset: "-0500" },
  { id: 344, name: "US/Hawaii", offset: "-1000" },
  { id: 345, name: "US/Indiana-Starke", offset: "-0600" },
  { id: 346, name: "US/Michigan", offset: "-0500" },
  { id: 347, name: "US/Mountain", offset: "-0700" },
  { id: 348, name: "US/Pacific", offset: "-0800" },
  { id: 349, name: "US/Samoa", offset: "-1100" },
];

const userRoles = [
  { id: 0, type: "System" },
  { id: 1, type: "System Admin" },
  { id: 10, type: "Admin" },
  { id: 20, type: "User" },
];

const exportedAttributes = [
  { id: "customer", name: "Customer" },
  { id: "billable", name: "Billable" },
];

export { dateFormats, timeFormats, timeZones, userRoles, exportedAttributes };
