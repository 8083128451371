<template>
    <v-card min-height="450px">
        <v-card flat>
            <v-card-title>Settings</v-card-title>
            <v-card-subtitle>Account Settings</v-card-subtitle>

            <v-card-text>
                <v-card flat>
                    <ValidationObserver ref="observer" v-slot="{}">
                        <v-form>
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12">
                                        <CustomLabel
                                            for="timezone"
                                            label="Timezone"
                                        />
                                        <CustomSelectField
                                            id="timezone"
                                            inputIcon="mdi-map-clock"
                                            inputPlaceholder="Timezone"
                                            :inputValue="
                                                updatePayload.settings
                                                    .timezone_id
                                            "
                                            :optionItems="timeZones"
                                            optionText="name"
                                            optionValue="id"
                                            @change="
                                                updateForm(
                                                    $event,
                                                    'updatePayload.settings.timezone_id'
                                                )
                                            "
                                        >
                                        </CustomSelectField>
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="12" sm="6">
                                        <CustomLabel
                                            for="date_formats"
                                            label="Date Format"
                                        />
                                        <CustomSelectField
                                            id="date_formats"
                                            inputPlaceholder="Date Format"
                                            :inputValue="
                                                updatePayload.settings
                                                    .date_format
                                            "
                                            :optionItems="dateFormats"
                                            optionText="style"
                                            optionValue="id"
                                            @change="
                                                updateForm(
                                                    $event,
                                                    'updatePayload.settings.date_format'
                                                )
                                            "
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <CustomLabel
                                            for="time_formats"
                                            label="Time Format"
                                        />
                                        <CustomSelectField
                                            id="time_formats"
                                            inputPlaceholder="Time Format"
                                            :inputValue="
                                                updatePayload.settings
                                                    .time_format
                                            "
                                            :optionItems="timeFormats"
                                            optionText="style"
                                            optionValue="id"
                                            @change="
                                                updateForm(
                                                    $event,
                                                    'updatePayload.settings.time_format'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions class="px-3">
                                <v-spacer
                                    class="d-flex justify-space-between align-end"
                                />
                                <CustomButton
                                    :block="$vuetify.breakpoint.xs"
                                    btnColour="primary"
                                    :btnIsLoading="updateOps.isLoading"
                                    btnLabel="Save"
                                    @click="updateSettings(updatePayload)"
                                />
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-card-text>
        </v-card>

        <v-divider />

        <v-card flat :disabled="!isOAuthUser">
            <v-card-title>Integration</v-card-title>
            <v-card-subtitle>Google Integration Options</v-card-subtitle>

            <v-card-text>
                <v-card flat>
                    <ValidationObserver ref="integration_observer" v-slot="{}">
                        <v-form>
                            <v-card-text>
                                <v-row dense>
                                    <!-- Google Calendar integration -->
                                    <v-col cols="12" sm="6" md="6">
                                        <CustomSwitch
                                            id="google_calendar"
                                            switchLabel="Google Calendar"
                                            :switchIsLoading="
                                                google.calendar.isLoading
                                            "
                                            :switchValue="
                                                google.calendar.isEnabled
                                            "
                                            @change="
                                                handleGoogleCalendarIntegration
                                            "
                                        />
                                    </v-col>

                                    <!-- Google Gmail integration -->
                                    <!-- <v-col cols="12" sm="6" md="6">
                                        <CustomSwitch
                                            id="google_gmail"
                                            switchLabel="Google Gmail"
                                            :switchIsLoading="
                                                google.gmail.isLoading
                                            "
                                            :switchValue="
                                                google.gmail.isEnabled
                                            "
                                            @change="
                                                handleGoogleGmailIntegration
                                            "
                                        />
                                    </v-col> -->

                                    <!-- Google Sheets integration -->
                                    <!-- <v-col cols="12" sm="6" md="6">
                                        <CustomSwitch
                                            id="google_sheets"
                                            switchLabel="Google Sheets"
                                            :switchIsLoading="
                                                google.sheets.isLoading
                                            "
                                            :switchValue="
                                                google.sheets.isEnabled
                                            "
                                            @change="
                                                handleGoogleSheetsIntegration
                                            "
                                        />
                                    </v-col> -->

                                    <!-- Google Docs integration -->
                                    <!-- <v-col cols="12" sm="6" md="6">
                                        <CustomSwitch
                                            id="google_docs"
                                            switchLabel="Google Docs"
                                            :switchIsLoading="
                                                google.docs.isLoading
                                            "
                                            :switchValue="google.docs.isEnabled"
                                            @change="
                                                handleGoogleDocsIntegration
                                            "
                                        />
                                    </v-col> -->
                                </v-row>
                            </v-card-text>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-card-text>
        </v-card>
    </v-card>
</template>

<script>
import { dateFormats, timeFormats, timeZones } from "@/services/OptionMappers";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapState } from "vuex";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    computed: {
        ...mapState("userSelfMgmt", ["user", "updatePayload", "updateOps"]),
        isOAuthUser() {
            return this.user.type > 0;
        },
    },
    data: () => ({
        enableLoading: false,
        enableCheckMark: false,
        dateFormats: dateFormats,
        timeFormats: timeFormats,
        timeZones: timeZones,
        google: {
            calendar: {
                isEnabled: false,
                isLoading: false,
            },
            docs: { isEnabled: false, isLoading: false },
            gmail: { isEnabled: false, isLoading: false },
            sheets: { isEnabled: false, isLoading: false },
        },
    }),
    methods: {
        async initGAPIClient() {
            await window.gapi.client.init({
                apiKey: "AIzaSyDgze_PO9u5UxNN80WhIAoeTeI1yYAnLTs",
                discoveryDocs:
                    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
            });
        },
        handleGCICallback(value) {
            console.log(value);

            let response = this.$store.dispatch(
                "userSelfMgmt/enableGoogleCalendarIntegration",
                value
            );

            console.log(response);
        },
        handleCheckMark() {
            console.log("Handling check mark...");
            this.enableCheckMark = true;
            setTimeout(() => {
                console.log("Dismissing check mark.");
                this.toggleCheck();
            }, 3500);
        },
        async handleGoogleCalendarIntegration(value) {
            console.log(value);
            let client = window.google.accounts.oauth2.initCodeClient({
                client_id:
                    "281981058345-ek0ls2ibbjka6kgf1vkmps2n2o990p3f.apps.googleusercontent.com",
                scope: "https://www.googleapis.com/auth/calendar",
                ux_mode: "popup",
                callback: this.handleGCICallback,
            });
            client.requestCode();
            // if (value) {
            //     this.google.calendar.isEnabled = value;
            //     this.google.calendar.isLoading = true;

            //     let response = await this.$store.dispatch(
            //         "userSelfMgmt/enableGoogleCalendarIntegration"
            //     );

            //     if (response.status != undefined && response.status == 200) {
            //         this.google.calendar.isLoading = false;
            //     } else {
            //         this.google.calendar.isEnabled = false;
            //         this.google.calendar.isLoading = false;
            //     }
            // } else {
            //     this.google.calendar.isEnabled = false;
            // }
        },
        async handleGoogleDocsIntegration(value) {
            if (value) {
                this.google.docs.isEnabled = value;
                this.google.docs.isLoading = true;

                let response = await this.$store.dispatch(
                    "userSelfMgmt/enableGoogleDocsIntegration"
                );

                if (response.status && response.status == 200) {
                    this.google.docs.isLoading = false;
                } else {
                    this.google.docs.isEnabled = false;
                    this.google.docs.isLoading = false;
                }
            } else {
                this.google.docs.isEnabled = false;
            }
        },
        async handleGoogleGmailIntegration(value) {
            if (value) {
                this.google.gmail.isEnabled = value;
                this.google.gmail.isLoading = true;

                let response = await this.$store.dispatch(
                    "userSelfMgmt/enableGoogleGmailIntegration"
                );

                if (response.status && response.status == 200) {
                    this.google.gmail.isLoading = false;
                } else {
                    this.google.gmail.isEnabled = false;
                    this.google.gmail.isLoading = false;
                }
            } else {
                this.google.gmail.isEnabled = false;
            }
        },
        async handleGoogleSheetsIntegration(value) {
            if (value) {
                this.google.sheets.isEnabled = value;
                this.google.sheets.isLoading = true;

                let response = await this.$store.dispatch(
                    "userSelfMgmt/enableGoogleSheetsIntegration"
                );

                if (response.status && response.status == 200) {
                    this.google.sheets.isLoading = false;
                } else {
                    this.google.sheets.isEnabled = false;
                    this.google.sheets.isLoading = false;
                }
            } else {
                this.google.sheets.isEnabled = false;
            }
        },
        populateSwitches(user) {
            // Google Calendar check
            if (!this.isOAuthUser) {
                return;
            }

            if (
                user.oauth_provider.google_scopes.includes(
                    "https://www.googleapis.com/auth/calendar"
                )
            ) {
                this.google.calendar.isEnabled = true;
            }
            // Google Docs check
            if (
                user.oauth_provider.google_scopes.includes(
                    "https://www.googleapis.com/auth/documents"
                )
            ) {
                this.google.docs.isEnabled = true;
            }
            // Google Gmail check
            if (
                user.oauth_provider.google_scopes.includes(
                    "https://mail.google.com/"
                )
            ) {
                this.google.gmail.isEnabled = true;
            }

            // Google Sheets check
            if (
                user.oauth_provider.google_scopes.includes(
                    "https://www.googleapis.com/auth/spreadsheets"
                )
            ) {
                this.google.sheets.isEnabled = true;
            }
        },
        toggleCheck() {
            this.google.calendar = false;
        },
        toggleLoading() {
            this.enableLoading = !this.enableLoading;
            if (this.enableLoading == false) {
                console.log(this.enableLoading);
                this.handleCheckMark();
            }
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("userSelfMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("userSelfMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
        async updateSettings(payload) {
            let validated = await this.$refs.observer.validate();

            if (validated) {
                let response = await this.$store.dispatch(
                    "userSelfMgmt/updateSelf",
                    {
                        settings: {
                            date_format: payload.settings.date_format,
                            time_format: payload.settings.time_format,
                            timezone_id: payload.settings.timezone_id,
                        },
                    }
                );

                if (response) {
                    this.$refs.observer.reset();
                }
            }
        },
    },
    name: "TabUserSettings",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {
        this.populateSwitches(this.user);
    },
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
