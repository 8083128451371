var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"450px"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Settings")]),_c('v-card-subtitle',[_vm._v("Account Settings")]),_c('v-card-text',[_c('v-card',{attrs:{"flat":""}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('CustomLabel',{attrs:{"for":"timezone","label":"Timezone"}}),_c('CustomSelectField',{attrs:{"id":"timezone","inputIcon":"mdi-map-clock","inputPlaceholder":"Timezone","inputValue":_vm.updatePayload.settings
                                                .timezone_id,"optionItems":_vm.timeZones,"optionText":"name","optionValue":"id"},on:{"change":function($event){return _vm.updateForm(
                                                $event,
                                                'updatePayload.settings.timezone_id'
                                            )}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('CustomLabel',{attrs:{"for":"date_formats","label":"Date Format"}}),_c('CustomSelectField',{attrs:{"id":"date_formats","inputPlaceholder":"Date Format","inputValue":_vm.updatePayload.settings
                                                .date_format,"optionItems":_vm.dateFormats,"optionText":"style","optionValue":"id"},on:{"change":function($event){return _vm.updateForm(
                                                $event,
                                                'updatePayload.settings.date_format'
                                            )}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('CustomLabel',{attrs:{"for":"time_formats","label":"Time Format"}}),_c('CustomSelectField',{attrs:{"id":"time_formats","inputPlaceholder":"Time Format","inputValue":_vm.updatePayload.settings
                                                .time_format,"optionItems":_vm.timeFormats,"optionText":"style","optionValue":"id"},on:{"change":function($event){return _vm.updateForm(
                                                $event,
                                                'updatePayload.settings.time_format'
                                            )}}})],1)],1)],1),_c('v-card-actions',{staticClass:"px-3"},[_c('v-spacer',{staticClass:"d-flex justify-space-between align-end"}),_c('CustomButton',{attrs:{"block":_vm.$vuetify.breakpoint.xs,"btnColour":"primary","btnIsLoading":_vm.updateOps.isLoading,"btnLabel":"Save"},on:{"click":function($event){return _vm.updateSettings(_vm.updatePayload)}}})],1)],1)]}}])})],1)],1)],1),_c('v-divider'),_c('v-card',{attrs:{"flat":"","disabled":!_vm.isOAuthUser}},[_c('v-card-title',[_vm._v("Integration")]),_c('v-card-subtitle',[_vm._v("Google Integration Options")]),_c('v-card-text',[_c('v-card',{attrs:{"flat":""}},[_c('ValidationObserver',{ref:"integration_observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('CustomSwitch',{attrs:{"id":"google_calendar","switchLabel":"Google Calendar","switchIsLoading":_vm.google.calendar.isLoading,"switchValue":_vm.google.calendar.isEnabled},on:{"change":_vm.handleGoogleCalendarIntegration}})],1)],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }